import React from 'react';


import IconAnimals from './icons/IconAnimals';
import IconDessert from './icons/IconDessert';
import IconEntertainment from './icons/IconEntertainment';
import IconEventPlanning from './icons/IconEventPlanning';
import IconFavors from './icons/IconFavors';
import IconMusic from './icons/IconMusic';
import IconPhotography from './icons/IconPhotography';
import IconRentals from './icons/IconRentals';
import IconServing from './icons/IconServing';
import IconStyling from './icons/IconStyling';
import IconVideography from './icons/IconVideography';
import IconCatering from './icons/IconCatering';

export const data = [
  {icon: <IconEventPlanning />, categoryName: 'eventPlanning', label: 'Event Planning'},
  {icon: <IconRentals />, categoryName: 'rentals', label: 'Rentals'},
  {icon: <IconCatering />, categoryName: 'catering', label: 'Catering'},
  {icon: <IconServing />, categoryName: 'serving', label: 'Serving'},
  {icon: <IconStyling />, categoryName: 'styling', label: 'Styling'},
  {icon: <IconPhotography />, categoryName: 'photography', label: 'Photography'},
  {icon: <IconVideography />, categoryName: 'videography', label: 'Videography'},
  {icon: <IconMusic />, categoryName: 'music', label: 'Music'},
  {icon: <IconEntertainment />, categoryName: 'entertainment', label: 'Entertainment'},
  {icon: <IconAnimals />, categoryName: 'animals', label: 'Animals'},
  {icon: <IconDessert />, categoryName: 'dessert', label: 'Dessert'},
  {icon: <IconFavors />, categoryName: 'favors', label: 'Favors'},
]
