import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './CategoryBar.module.css';
import { NamedLink } from '../index';
import { data } from './config';

const CategoryBar = props => {
  const { rootClassName, className, fillColor } = props;

  const renderCategoryLinks = () => {
    return data.map((d, index) => {
      const searchParams = `pub_category=has_any:${d.categoryName}`
      return (
        <li key={index} className={css.linksItem}>
          <NamedLink className={css.link} name='SearchPage' to={{ search: searchParams }}>
            <span className={css.linkIcon}>{d.icon}</span>
            <span className={css.linkLabel}>{d.label}</span>
          </NamedLink>
        </li>

      );
    });
  }

  return (
    <div className={css.linksListHolder}>
      <ul className={css.linksList}>
        {renderCategoryLinks()}
      </ul>
    </div>

  );
};

CategoryBar.defaultProps = {
  rootClassName: null,
  className: null,
};

CategoryBar.propTypes = {
  rootClassName: string,
  className: string,
};

export default CategoryBar;
